let validatePhoto = (rule, value, callback) => {
	if (!value.length) {
		return callback(new Error('至少上传一张主图'));
	} else {
		callback()
	}
}
let validateThumb = (rule, value, callback) => {
	if (value == '') {
		return callback(new Error('请上传封面图'));
	} else {
		callback()
	}
}
let validateCustom = (rule, value, callback) => {
	value.forEach(item => {
		if (item == '') {
			return callback(new Error('请填写必填项'));
		} else {
			callback()
		}
	})
}
let validateStock = (rule, value, callback) => {
	var reg = /(^[\-0-9][0-9]*(.[0-9]+)?)$/;
	if (value <= 0) {
		return callback(new Error('库存至少为1~'));
	}else if(!reg.test(value)){
		return callback(new Error('库存数量必须为数字~'));
	}else {
		callback()
	}
}

//验证价格
let validateMoney = (rule, value, callback) => {
	var reg = /(^[\-0-9][0-9]*(.[0-9]+)?)$/;
	if (value <= 0) {
		return callback(new Error('价格至少为1~'));
	}else if(!reg.test(value)){
		return callback(new Error('价格必须为数字~'));
	}else {
		callback()
	}
}
export default {
	// pinpai:[{
	// 	required: true,
	// 	message: '请选择品牌',
	// 	trigger:'change'
	// }],
	pinpai:[{
		required: true,
		message: '请选择品牌',
		trigger:'change'
	}],
	title: [{
		required: true,
		message: '请输入产品标题'
	}],
	description: [{
		required: true,
		message: '请输入产品卖点'
	}],
	pfenlei:[{
		required: true,
		message: '请选择店铺分类'
	}],
	xuanze:[{
		required: true,
		message: '请选择品牌'
	}],
	model: [{
		required: true,
		message: '请输入产品型号'
	}],
	stock: [{
		validator: validateStock,
		required: true
	}],
	money: [{
		validator: validateMoney,
		required: true
	}],
	thumb: [{
		validator: validateThumb,
		required: true
	}],
	photo: [{
		validator: validatePhoto,
		required: true
	}],
	value: [{
		validator: validateCustom,
		required: true
	}]
}
