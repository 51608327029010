<template>
	<div class="content">
		<div class="tips">
			<div>小提示：</div>
			<p>1.所填信息中应避免出现“<span>官网</span>”、“<span>官方网站</span>”等字样，避免出现火星文等影响正常阅读符号。</p>
			<p>2.所填信息中禁止填写、穿插联系方式或变形联系方式，不能在图片中带任何联系方式或公司名称，如二维码、电话、网址、电子邮箱、QQ等。</p>
			<p>3.严重违反《<a :href="$store.state.ROOT_URL+'/push_rules.html'" target="_blank">消毒设备网信息发布规则 </a>》的用户，其产品信息将不被推荐、其账号将会被限制使用。</p>
		</div>
		<a href=""></a>
		<el-form :model="form" label-width="120px" :rules="rules" ref="form" inline-message>
			<div class="title">基本信息</div>
			<el-form-item label="产品所属分类">
				<div class="cate">
					<p>
						<template v-for="(item,index) in cate.name">
							{{item}}<i class="el-icon-arrow-right" v-if="index!=cate.name.length-1" />
						</template>
					</p>
					<div class="product-btn" @click="handleToCate">返回重新选择</div>
				</div>
			</el-form-item>
			<div class="boxBox"  v-if="caijiOpen > 0">
				<el-form  :model="forms" label-width="120px" ref="forms" inline-message style="display: flex;">
					<div class="left user-left-reputa-info">
						<el-form-item label="网址">
							<i class="el-icon-warning" @mouseover="handlePopoverShow(0)" @mouseleave="handlePopoverHide(0)">
								<transition name="el-fade-in-linear">
									<div class="popover" v-show="popover[0]">
										<div class="texts">
											小提示：选中爱采购或1688产品详情页顶部链接,右键选择复制(ctrl+c)链接,把选中链接粘贴(ctrl+v)在网址框里(仅限于爱采购和1688)
										</div>
										<div class="picBox">
											<img src="../../assets/img/lianjie.png" >
										</div>
									</div>
								</transition>
							</i>
							<el-input v-model="forms.infourl" class="name-text" placeholder="请输入网址(仅限于爱采购和1688)" />
						</el-form-item>
					</div>
					<div class="right">
						 <el-form-item>
							<button class="form-submit-btn" type="button" @click="hadelBan"  v-loading.fullscreen.lock="fullscreenLoading" 
							element-loading-background="rgba(255,255,255, 0.5)">一键搬家 </button> <span class="tiao">您当前剩余<span class="num">{{caijiOpen}}</span> 条采集数量<span>
						</el-form-item>
					</div>
				</el-form>
			</div>
			<el-form-item label="信息标题" prop="title">
				<el-input v-model="form.title" class="name-text" />
				<p class="form-tips">最多50字，除了填写产品词外，最好包含品牌、型号、特性及修饰词等内容来丰富标题，禁止出现任何联系方式</p>
			</el-form-item>
			<el-form-item label="产品卖点" prop="description">
				<el-input type="textarea" v-model="form.description" :rows="4" maxlength="150" show-word-limit>
				</el-input>
				<p class="form-tips">产品卖点可填写对产品的特色和主打优势进行说明突出的补充说明，最多150字</p>
			</el-form-item>
			<el-form-item label="关键词">
				<el-input v-model="form.keywords[0]" class="keywords-text"></el-input>
				<el-input v-model="form.keywords[1]" class="keywords-text"></el-input>
				<el-input v-model="form.keywords[2]" class="keywords-text"></el-input>
				<p class="form-tips">小窍门：1.这是买家搜索的核心词，您可以从信息标题中提炼出中心词，注意不要过长</p>
				<p class="form-tips form-tips-keywords-line">2.尽量使用市场上或行业内的常用词，或填写产品的通称/别称，以便符合买家的搜索习惯</p>
			</el-form-item>
			<!-- 自定义分类 -->
			<el-form-item label="店铺产品分类" prop="seller_catid"   >
				<el-cascader v-model="form.seller_catid" :options="options2" :props="catidProps2"/>
				<!-- <el-button class="add-catid" @click="goAddCatid">添加分类</el-button> -->
			</el-form-item>
			<div class="attr-wrap">
				<div class="attr-label">产品属性</div>
				<div class="attr-content">
					<el-form-item label="品牌:">
						<el-select v-model="form.brand_id" placeholder="请选择" v-if="!isBrand">
							<el-option v-for="item in brand" :label="item.title" :value="item.title" :key="item.id" />
						</el-select>
						<el-input v-model="form.brand_id" v-else />
						<span class="brand-btn" @click="isBrand=!isBrand">{{isBrand?'选择热门品牌':'自定义品牌'}}</span>
					</el-form-item>
					<el-form-item label="型号:" prop="model">
						<el-input v-model="form.model"></el-input>
					</el-form-item>
					<el-form-item v-for="(items,index) in styleTitle" :key="items.id" :label="`${items.title}:`"
						:prop="items.required==1?'items.value':''">
						<el-input v-model="items.value"></el-input>
					</el-form-item>
					<div class="add-attr" v-for="(item,index) in addStyle" :key="index">
						<el-input v-model="item.add_style" class="attr-name" placeholder="属性名"></el-input>
						<span>:</span>
						<el-input v-model="item.add_value" placeholder="属性值"></el-input>
					</div>
					<div class="attr-add-btn" @click="handleAddAttr"><i class="el-icon-plus" />添加产品属性</div>
				</div>
			</div>
			<div class="title">销售信息</div>
			
			<el-form-item label="单位">
				<el-select v-model="form.util" placeholder="请选择单位">
				     <el-option v-for="item in danwei" :key="item.id" :label="item" :value="item" />
				</el-select>
				<!-- <el-select filterable v-model="form.util">
					<el-option label="台" value="台" />
					<el-option label="个" value="个" />
					<el-option label="件" value="件" />
					<el-option label="套" value="套" />
					<el-option label="吨" value="吨" />
					<el-option label="支" value="支" />
					<el-option label="张" value="张" />
					<el-option label="千克" value="千克" />
					<el-option label="立方米" value="立方米" />
					<el-option label="平方米" value="平方米" />
				</el-select> -->
			</el-form-item>
			<el-form-item label="价格" prop="money">
				<el-input class="price-input" v-model="form.money">
					<template slot="append">元</template>
				</el-input>
			</el-form-item>
			<el-form-item label="库存" prop="stock">
				<el-input class="price-input" v-model="form.stock">
					<template slot="append">{{form.util}}</template>
				</el-input>
			</el-form-item>
			<div class="title">主图和详细说明</div>
			<el-form-item label="产品主图" prop="photo">
				<div class="photo-btn" @click="isFileDiaShow=true">点击上传</div>
				<ul class="img-list">
					<li v-for="(item,index) in form.photo" :key="index">
						<img :src="$store.state.IMG_URL + '/'+item" />
						<div class="img-delete" @click="handleImageDetele(index)">
							<i class="el-icon-delete"></i>
						</div>
					</li>
				</ul>
				<p class="form-tips">建议您上传750*750像素以上的大图（至少400*400像素），上传白底图可增加曝光机会喔！<br />
					<router-link to="" class="img-link">产品主图设计好，提高点击抢商机<i class="el-icon-arrow-right" /></router-link>
				</p>
			</el-form-item>
			<fileDialog :show="isFileDiaShow" :close.sync="isFileDiaShow"
				@eventSubmit="handleFile" :initImg="form.photo" />
			<el-form-item label="详细说明">
				<tinyMce :content="form.content" :change.sync="form.content" />
			</el-form-item>
			<div class="form-submit-wrap">
				<button class="form-submit-btn" type="button" @click="handleSubmit"  v-loading.fullscreen.lock="fullscreenLoading"  
				element-loading-background="rgba(255,255,255, 0.5)">发布</button>
			</div>
		</el-form>
	</div>
</template>

<script>
	import rules from '@/assets/js/productRules'
	import fileDialog from '@/components/plug/fileDialog'
	import tinyMce from '@/components/plug/tinyMce'
	import Axios from 'axios'
	export default {
		data() {
			return {
				popover: [false, false, false],
				fullscreenLoading: false,
				routeName:'',
				styleTitle:[],
				addStyle:[],
				brand:[],
				cate:[],
				forms:{
					infourl:'',
					cat_id: '',
					user_id:this.$store.state.SELLER_ID,
				},
				form: {
					user_id: this.$store.state.SELLER_ID,
					cate:[],
					cat_id: [],
					title: '',
					description: '',
					seller_catid: [],
					keywords: [],
					brand_id: '',
					model: '',
					style_id: [],
					value: [],
					add_style: [],
					add_value: [],
					money: '',
					photo: [],
					content: '',
					util: '',
					stock: '',
				},
				isBrand: false,
				rules,
				isInit: true,
				styleProp: [],
				options: [],
				catidProps2: {
					value: 'id',
					label: 'catname',
					children: 'child',
				},
				catidDanwei:{
					value: 'id',
					label: 'id',
				},
				pChild: [], //分类值
				options2: [], //分类列表
				danwei:[],//单位
				isFileDiaShow: false,
				albumData:{list: []},
				caijiOpen:sessionStorage.getItem("caijiOpen"),
				routeSwitch: true,
			}
		},
		created() {
			this.routeName = this.$route.name
			this.$axios.post('/getSellerCategory').then(data => {
				data.forEach(item => {
					if (!item.child.length) {
						delete item.child
					}
				})
				this.options2 = data
			})
			this.$axios.post('/getDanwei').then(data => {
				this.danwei = data
			})
			
		},
		methods: {
			// iszheng(){
			// 	var num = $(".price-input").val()
			// 	console.log("num",num)
			// 	if(num <= 0){
			// 		$(".price-input").val("")
			// 		this.$message.error('价格至少大于0~')
			// 		return false
			// 	}else{
			// 		return true
			// 	}
			// },
			handlePopoverShow(index) {
				this.popover.splice(index, 1, true)
			},
			handlePopoverHide(index) {
				this.popover.splice(index, 1, false)
			},
			// 一键搬家
			hadelBan(){
				if(this.forms.infourl != ''){
					if(this.caijiOpen > 0){
						this.fullscreenLoading = true
						let c = this.form.cat_id.slice(-1)
						this.forms.cat_id = String(c)
						Axios.post('/getAcgGoodsInfo',this.$qs.stringify(this.forms)).then(data => {
							let datas = data.data
							console.log("datas",datas)
							if(datas.code == 200){
								this.caijiOpen--
								this.fullscreenLoading = false
								this.form.title = datas.data.goods.title
								this.form.description = datas.data.goods.description
								this.form.keywords = datas.data.goods.keywords
								this.form.brand_id = datas.data.goods.brand_id
								this.form.model = datas.data.goods.model
								this.form.util = datas.data.goods.danwei
								this.form.money = datas.data.goods.money
								this.form.content = datas.data.goods.content
								
								this.form.seller_catid = datas.data.goods.seller_catid
								this.form.stock = Number(10)   //默认值
								 
								
								// 图片
								let photo = datas.data.goodsphoto
								let photoArr = []
								photo.map(item =>{
									photoArr.push(item.photo)
								})
								this.form.photo = photoArr
								//默认的
								let arr = datas.data.goodsstyle;
								let styTitleArr = this.styleTitle;
								for(var k = 0;k<= arr.length;k++){
									console.log(">arr[k]",k)
									if(arr[k].style_id){
										this.styleTitle.unshift(arr[k])
										console.log("有id的",arr[k])
									}else{
										console.log("没有id的",arr[k])
										this.addStyle.unshift({
											add_style:arr[k].title,
											add_value:arr[k].value
										})
										
									}
								}
								
								console.log("styleTitle",styleTitle)
								// arr.map(items =>{
								// 	if(items.style_id){
								// 		this.styleTitle.value.push(items.value) 
								// 		this.styleTitle.style_id.push(items.style_id)
										
								// 		console.log("styleTitle",this.styleTitle)
								// 	}else{
								// 		//不存在
								// 		this.addStyle.add_style.push(items.title)
								// 		this.addStyle.add_value.push(items.value)
								// 	}
								// })
							}else{
								this.fullscreenLoading = false
								this.$message.error(datas.msg);
							}
						})
					}else{
						this.$message.error("您的采集次数已用完!");
					}
					
				}else{
					this.$message.error("网址为空,无法采集信息~");
				}
				
				
			},
			handleChange(data) {
				this.form.brand_id = data
			},
			handleToCate() {
				this.$emit('eventToCate')
			},
			handleAddAttr() {
				this.addStyle.push({
					add_style:'',
					add_value:''
				})
			},
			handleSubmit() {
				this.fullscreenLoading = true;
				let addStyles= this.addStyle
				
				let styleTitles= this.styleTitle
				for(var k =0;k<styleTitles.length;k++){
					if(styleTitles[k].value && styleTitles[k].value  != ''){
						this.form.value[k] = styleTitles[k].value 
						this.form.style_id[k] =  styleTitles[k].style_id ? styleTitles[k].style_id :styleTitles[k].id
					}
				}
				console.log(">>>",addStyles)
				for(var h =0;h<addStyles.length;h++){
					this.form.add_style.push(addStyles[h].add_style)
					this.form.add_value.push(addStyles[h].add_value )
					
				}
				console.log("forms",this.form)
				this.$refs['form'].validate(valid => {
					if (valid) {
						if (this.form.content.includes('"static/tinymce/plugins/bdmap/bd')) {
							this.form.content = this.form.content.replace(/"static\/tinymce\/plugins\/bdmap\/bd/g,'"/static/tinymce/plugins/bdmap/bd')
						}
						this.fullscreenLoading = false;
						this.routeSwitch = false
						this.$emit('eventSubmit', this.form)
					} else {
						this.fullscreenLoading = false;
						this.$message.error('请补全必填项')
						return false;
					}
				})
			},
			handleImageDetele(index) {
				this.form.photo.splice(index, 1)
			},
			handleFile(list) {
				this.form.photo = list
			}
		},
		beforeDestroy() {
			console.log(this.routeSwitch)
			if (this.routeName == 'ProductRelease' && this.routeSwitch  && this.orForm != JSON.stringify(this.form)) {
				sessionStorage.setItem('unfinishedForm', JSON.stringify(this.form))
			}
		},
		mounted(){
			console.log(this.routeName )
			if (this.routeName == 'ProductRelease') {
				this.orForm = JSON.stringify(this.form)
				window.addEventListener('beforeunload', e => {
					if (this.orForm != JSON.stringify(this.form)) {
						this.form.cate = this.form.cate
						sessionStorage.setItem('unfinishedForm', JSON.stringify(this.form))
					}
				});
				if (sessionStorage.getItem('unfinishedForm')) {
					// if(){ //存在且不为空执行
						this.$confirm('您有上次未完成发布的产品, 是否继续填写?', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then((res) => {
							// this.$router.push("/product/release")
							this.form = JSON.parse(sessionStorage.getItem('unfinishedForm'))
							this.routeSwitch = false
							this.form.cate = this.form.cate
							this.cate = this.form.cate
							this.form.user_id = this.$store.state.SELLER_ID;
						}).catch(() => {
							sessionStorage.removeItem('unfinishedForm')
							this.$router.push("/product")
						})
					// }
				}
			}
		},
		props: ['cate', 'revForm', 'styleList','tinyMce'],
		computed: {
			init() {
				const {
					cate,
					revForm,
					tinyMce
				} = this
				return {
					cate,
					revForm,
					tinyMce,
				}
			}
		},
		// destroyed() {
		// 	sessionStorage.removeItem('unfinishedForm')
		// },
		watch: {
			init(val) {
				const {
					cate,
					revForm,
					tinyMce
				} = val
				
				this.form.cate = cate
				this.cate=cate
				if (this.isInit && cate.id.length && revForm) {
					this.isInit = false
					this.form = revForm
				}
			},
			cate(data) {
				this.form.cat_id = data.id
				this.$axios.post('/getCategoryStyle', {
					catid: data.id[data.id.length - 1]
				}).then(data => {
					this.styleTitle = data
					this.styleTitle.forEach(item => {
						this.styleTitle.style_id = item.id
						this.styleTitle.value = ""
					})
					// this.form.style_id.forEach((item, index) => {
					// 	this.styleProp.forEach(items => {
					// 		if (item == items.id) {
					// 			this.form.value[index] = items.value
					// 		}
					// 	})
					// })
				})
				this.$axios.post('/getSellerBrand', {
					cat_id: data.id[data.id.length - 1]
				}).then(data => {
					this.brand = data
				})
			},
			styleList(val) {
				this.styleProp = val
			},
			tinyMce(val){
				// console.log("富文本",val)
			}
		},
		components: {
			fileDialog,
			tinyMce
		}
	}
</script>

<style scoped>
	.tips {
		background: #ecf4ff;
		padding: 7px 32px 14px;
		font-size: 14px;
		color: #666666;
		border-radius: 5px;
		margin: 28px 0 6px;
	}

	.tips div {
		line-height: 36px;
	}

	.tips p {
		line-height: 25px;
		padding: 0 2px;
	}

	.tips span {
		color: #e60000;
	}

	.tips  a {
		color: #0194fe;
	}

	.title {
		font-size: 14px;
		font-weight: 700;
		height: 41px;
		line-height: 41px;
		border-bottom: 1PX solid #e0e0e0;
		margin-bottom: 12px;
	}

	.cate {
		display: flex;
		align-items: center;
	}

	.cate p {
		color: #ffb30f;
		margin-right: 42px;
	}

	.cate p i {
		margin: 0 5px;
	}

	.product-btn {
		display: inline-block;
		padding: 0 10px;
		height: 30px;
		background: #0f69dc;
		color: #fff;
		border-radius: 5px;
		text-align: center;
		line-height: 30px;
		cursor: pointer;
		position: relative;
	}

	.content>>>.el-form-item__label {
		color: #333;
	}

	.content>>>input {
		height: 30px;
	}

	.content>>>.el-textarea {
		width: 566px;
	}

	.name-text {
		width: 520px;
	}

	.form-tips {
		font-size: 12px;
		line-height: 25px;
		color: #333333;
	}

	.keywords-text {
		width: 168px;
		margin-right: 13px;
	}

	.form-tips-keywords-line {
		margin-left: 48px;
	}

	.attr-wrap {
		display: flex;
		font-size: 14px;
		margin-bottom: 28px;
	}

	.attr-label {
		width: 120px;
		text-align: right;
		padding: 0 12px 0 0;
		box-sizing: border-box;
		line-height: 38px;
	}

	.attr-content {
		width: 756px;
		box-sizing: border-box;
		background: #fafafa;
		border: 1PX solid #f1f1f1;
		padding: 43px 0 23px 18px;
	}

	.attr-wrap>>>.el-form-item {
		margin-bottom: 8px;
	}

	.attr-wrap>>>.el-input:not(.el-input--suffix) {
		width: 145px;
	}

	.brand-btn {
		color: #0f69dc;
		cursor: pointer;
		margin-left: 10px;
	}

	.attr-add-btn {
		font-size: 12px;
		color: #0f69dc;
		cursor: pointer;
		padding: 5px;
		margin-top: 8px;
	}

	.attr-add-btn i {
		font-weight: 700;
		color: #0f69dc;
	}

	.add-attr {
		display: flex;
		align-items: center;
		margin-bottom: 10px;
	}

	.add-attr span {
		margin: 0 10px;
	}

	.content>>>.el-radio__inner {
		width: 16px;
		height: 16px;
	}

	.price-input {
		width: 172px;
	}

	.content .img-link {
		font-size: 12px;
		color: #0f69dc;
		line-height: 22px;
	}

	.video-list {
		display: flex;
		margin-top: 12px;
	}

	.video-list li {
		width: 127px;
		height: 81px;
		position: relative;
	}

	.video-delete {
		width: 14px;
		height: 14px;
		border-radius: 50%;
		background: rgba(0, 0, 0, .5);
		color: #fff;
		position: absolute;
		right: 4px;
		top: 5px;
		text-align: center;
		line-height: 14px;
		font-size: 6px;
	}

	.form-submit-wrap {
		border-top: 1PX solid #e0e0e0;
		padding: 34px 0;
		text-align: center;
	}

	.form-submit-btn {
		width: 80px;
		height: 32px;
		color: #fff;
		background: #0f69dc;
		border: none;
		border-radius: 5px;
	}

	.photo-btn {
		display: inline-block;
		padding: 0 10px;
		height: 30px;
		background: #0f69dc;
		color: #fff;
		border-radius: 5px;
		text-align: center;
		line-height: 30px;
		cursor: pointer;
	}

	.img-list {
		display: flex;
		flex-wrap: wrap;
		margin-top: 7px;
	}

	.img-list li {
		position: relative;
		border: 1PX solid #ededed;
		width: 80px;
		height: 80px;
		margin-right: 15px;
		text-align: center;
	}

	.img-list img {
		max-width: 80px;
		max-height: 80px;
	}

	.img-delete {
		width: 17px;
		height: 17px;
		position: absolute;
		right: 0;
		top: 0;
		background: rgba(0, 0, 0, .5);
		color: #fff;
		text-align: center;
		line-height: 17px;
		font-size: 12px;
	}
	/* 搬家 */
	.texts{
		line-height: 50px;
		font-size: 16px;
		padding: 0 20px;
		border-bottom: 1px solid #ccc;
	}
	.left i{
		margin-left: -10px;
	}
	.popover{
		border: 2px solid rgb(204, 204, 204);
		z-index:1000;
	}
	.popover .picBox{
		margin-top: 10px;
		padding: 0 20px;
	}
	.right .tiao{
		margin-left: 10px;
		font-size: 12px;
		color: #666;
	}
	.right .num{
		color:#0194fe;
	}
	.boxBox .form-submit-btn{
		line-height: 32px;
	}
</style>
